:root {
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@font-face {
  font-family: poppinsBold;
  src: url(../font/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(../font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: poppinsLight;
  src: url(../font/Poppins-Light.ttf);
}
@font-face {
  font-family: poppinsMedium;
  src: url(../font/Poppins-Medium.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.information {
  background-color: #181c14;
  div {
    font-size: 13.35px;
    font-weight: bold;
    font-family: poppinsSemiBold;
    a {
      color: white;
      text-decoration: none;
      font-weight: bold;
    }
  }
}
.nav-position_container {
  position: fixed;
  z-index: 100000000;
  background-color: white;
  top: 3;
  left: 0;
  right: 0;
  height: 100px;
}
.nav_container {
  padding: 20px;
  height: 70px;
  box-shadow: var(--shadow);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 150px;
  }
}
.Navigation_Container {
  display: flex;
  align-items: center;
  gap: 20px;
  ul {
    display: flex;
    gap: 30px;
    margin-top: 10px;
    li {
      list-style-type: none;
      .Link {
        text-decoration: none;
        color: var(--nav-text-color);
        font-weight: 500;
        font-size: 15px;
        transition: 250ms;
        font-family: poppinsMedium;
      }
      .Link:hover {
        color: #b8001f;
      }
    }
  }
}
.Cart_COunt {
  background-color: red;
  border-radius: 100%;
  text-align: center;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none !important;
  font-weight: bold;
  font-family: poppinsSemiBold;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: -7px;
}
.nav-sm {
  display: none;
}
.Search-container {
  display: flex;
  align-items: center;
  border: 1px solid #00000068;
  padding: 10px;
  border-radius: 5px;
}
.Search-icon {
  transition: 250ms;
  cursor: pointer;
}
.Search-icon:hover {
  color: #b8001f;
}
.time-format {
  font-family: poppinsMedium;
}
.menu {
  font-family: poppinsSemiBold;
  text-transform: uppercase;
}
.menu-hr {
  width: 100%;
  height: 0.5px;
  background-color: #00000068;
}
.time {
  position: absolute;
  bottom: 50px;
  left: 10px;
  right: 10px;
  font-family: poppinsMedium;
}
.sub-list {
  padding: 0 100px !important;
}
@media screen and (max-width: 768px) {
  .nav-position_container {
    display: none;
  }
  .nav-sm {
    display: block;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  }
  .information-sm {
    background-color: #181c14;
    div {
      font-size: 9px;
      font-weight: bold;
      font-family: var(--font);
      a {
        color: white;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
  .nav-logo-sm {
    width: 150px;
  }
  .nav-cont-sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  .hamburger {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
  .navigation-box-sm {
    visibility: hidden;
    position: fixed;
    top: 25px;
    left: 0;
    width: 0 !important;
    height: 100%;
    overflow: hidden;
    background-color: white;
    transition: 350ms;
  }
  .navigation-box-sm-active {
    transition: 250ms;
  }
  .navigation-box-sm-active {
    visibility: visible;
    position: fixed;
    top: 25px;
    left: 0;
    width: 290px !important;
    height: 100%;
    transition: 250ms;
    background-color: white;
  }
  .Navigation_Container_sm {
    margin-top: 15px;
    padding: 15px;
    ul {
      display: flex;
      flex-direction: column;
      padding: 0 !important;
      li {
        height: 50px;
        list-style-type: none;
        .Link_sm {
          border-radius: 10px;
          height: 50px;
          padding: 0 10px;
          width: 100%;
          display: flex;
          font-size: 14px;
          color: var(--nav-text-color);
          font-family: var(--font);
          font-weight: bold;
          text-decoration: none;
        }
      }
      .wishlistandcart {
        border-radius: 10px;
        height: 50px;
        padding: 0 10px;
        width: 100%;
        font-size: 14px;
        display: flex;
        margin-top: 0 !important;
        color: var(--nav-text-color);
        font-family: var(--font);
        font-weight: bold;
        text-decoration: none;
        justify-content: space-between;
        .Cart_COunt-sm {
          background-color: red;
          padding: 3px 8px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: white !important;
          border-radius: 10px;
        }
      }
    }
  }
  .close-btn {
    height: 50px;
    width: 50px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
