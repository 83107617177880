:root {
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@font-face {
  font-family: poppinsBold;
  src: url(../font/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(../font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: poppinsLight;
  src: url(../font/Poppins-Light.ttf);
}
@font-face {
  font-family: poppinsMedium;
  src: url(../font/Poppins-Medium.ttf);
}

.breadcrumbs {
  padding: 10px 20px;
  color: var(--text-color);
  font-family: var(--font);
  text-transform: capitalize;
  font-weight: 600;
}
.headercontact {
  font-size: 23px;
  color: var(--text-color);
  font-family: poppinsSemiBold;
}
.headercontact2 {
  font-size: 13px;
  color: #787878;
  font-family: poppinsMedium;
  margin-top: 10px;
}
.instruction {
  font-family: poppinsLight;
  color: #787878;
  font-size: 12px;
  width: 400px;
  margin-top: 20px;
}
.headercontact2::first-letter {
  text-transform: capitalize;
}
.form-input {
  overflow: scroll !important;
  scrollbar-width: none;
}
.form-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100% !important;
  padding: 0 !important;
}
.form-blob {
  width: 300px;
}
.form-body {
  margin-top: 20px;
}
.form-flex {
  display: flex;
  align-items: center;
  gap: 30px;
}
.review-form {
  width: 500px;
  height: auto !important;
  label {
    font-family: poppinsMedium;
    font-size: 13px;
    color: #181c14 !important;
  }

  input {
    border: 1px solid #e3e3e3;
    padding: 8px 10px;
    border-radius: 5px;
    font-family: poppinsMedium;
    font-size: 13px;
    width: 100% !important;
    outline: blue !important;
  }
  ::placeholder {
    font-family: poppinsMedium;
    font-size: 12px;
  }
  select {
    font-family: poppinsMedium;
    font-size: 13px;
    width: 100% !important;
    border: 1px solid #e3e3e3;
    padding: 8px 10px !important;
    color: #181c14 !important;
    border-radius: 5px;
    height: 40px !important;
    outline: none !important;
    background-color: transparent;
  }
  input,
  select,
  textarea {
    font-size: 13px !important;
  }

  textarea {
    font-family: poppinsMedium;
    font-size: 13px !;
    border: 1px solid #e3e3e3;
    padding: 8px 10px;
    border-radius: 5px;
    outline: none !important;
    resize: none;
    height: 80px !important;
  }
  .review-button {
    font-family: poppinsMedium;
    font-size: 13px;
    width: 100% !important;
    color: #181c14 !important;
    margin-top: 10px;
    border: none;
    padding: 10px 0;
    border-radius: 10px;
    transition: 350ms;
  }
  .review-button:hover {
    color: white !important;
    background-color: var(--default-color);
  }
}
.error {
  color: red;
  font-size: 12px;
  font-family: poppinsMedium;
}
@media screen and (max-width: 768px) {
  .form-flex {
    display: block;
  }
  .form-blob {
    display: none;
  }
  .headercontact {
    padding: 10px 20px;
    margin-top: 30px;
  }
  .headercontact2 {
    padding: 0 20px;
  }
  .instruction {
    padding: 0 20px;
    width: 100% !important;
  }
  .review-form {
    width: 100% !important;
  }
  .form-body {
    margin-top: 0 !important;
    width: 100% !important;
  }
  .upload-area {
    width: 350px;
  }
  .form-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 !important;
  }
  .frm {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 540px) {
  .account {
    width: 350px;
  }
}
@media screen and (max-width: 420px) {
  .account {
    width: 300px;
  }
}
