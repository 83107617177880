:root {
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@font-face {
  font-family: poppinsBold;
  src: url(../font/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(../font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: poppinsLight;
  src: url(../font/Poppins-Light.ttf);
}
@font-face {
  font-family: poppinsMedium;
  src: url(../font/Poppins-Medium.ttf);
}
.feed {
  overflow-x: hidden; /* Prevents horizontal scrolling */
  width: 100%;
}

.slick-slider {
  overflow: hidden; /* Prevents horizontal scrolling due to Slick */
}

.feed-back-container {
  width: 100% !important; /* Ensures it takes full width */
  max-width: 1200px; /* Prevents it from being too wide on larger screens */
  margin: auto; /* Centers it properly */
  overflow: hidden; /* Prevents unnecessary scrolling */

  width: 95% !important;
  .header {
    .feed_Back_SUbhead {
      width: 10%;
      text-align: center;
      border-radius: 10px;
      margin: auto;
      background-color: var(--default-light);
      color: var(--default-color);
      opacity: 0.8;
      font-family: poppinsMedium;
    }
    .feed-back-header {
      text-align: center;
      font-weight: bold;
      font-size: 25px;
      color: var(--text-color);
      font-family: var(--font);
      margin: 10px 0;
    }
  }
}
.feed-backs {
  width: 330px;
  height: 280px;
  margin: 10px;
  .user-feedback-name {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-color);
    opacity: 0.9;
    font-family: poppinsSemiBold;
  }
  .user-feedback-category {
    font-size: 12px;
    font-weight: 500;
    color: var(--text-color);
    opacity: 0.7;
    font-family: poppinsMedium;
  }
  .feed-back-content {
    width: 100%;
    height: 140px;
    overflow: scroll;
    scrollbar-width: none;
    font-weight: 500;
    color: var(--text-color);
    opacity: 0.6;
    font-family: poppinsMedium;
    font-size: 15px;
  }
}
.slick-slide > div {
  margin: 0 15px;
}

.feed-back {
  padding: 20px;
}
@media screen and (max-width: 768px) {
  .feed-back {
    padding: 1px;
  }
  .feed-backs {
    margin: 0 !important;
  }
}
@media screen and (max-width: 480px) {
  .feed_Back_SUbhead {
    width: 30% !important;
  }
  .feed-back-header {
    font-size: 20px;
  }
}
