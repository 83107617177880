:root {
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@font-face {
  font-family: poppinsBold;
  src: url(../font/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(../font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: poppinsLight;
  src: url(../font/Poppins-Light.ttf);
}
@font-face {
  font-family: poppinsMedium;
  src: url(../font/Poppins-Medium.ttf);
}
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.product_img {
  width: 120px;
  height: 120px;
  margin-left: 30px;
}

.Items hr {
  display: none !important;
}
.trend {
  padding: 30px 60px;
}
.overlay {
  display: none; /* Initially hidden */
  position: fixed; /* Covers the entire viewport */
  top: 0;
  left: 0;
  z-index: 10000000000;
  width: 100%;
  height: 100%; /* Change to cover full viewport height */
  background-color: rgba(255, 255, 255, 0.9); /* Add slight transparency */
  overflow-y: auto; /* Allows scrolling if content is larger than viewport */
  border-radius: 10px;
  box-shadow: 1px 1px 17px 0px rgba(0, 0, 0, 0.13);
  padding: 30px;
  background-color: white;
}

.overlaydisplay {
  display: block;
}
.bottom {
  margin-top: 50px;
  display: flex;
}
.quantityBox {
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  border: 2px solid #ecebe4;
  width: 120px !important;
  padding: 5px 10px 5px 10px !important;
  font-size: 14px;
}
.total {
  background-color: #ecebe4;
  padding: 10px 40px !important;
  cursor: pointer;
  transition: 0.2s;
}
.total:hover {
  background-color: #ebe9d0;
}
.cancel {
  border-radius: 100%;
  padding: 5px;
  margin-top: 50px;
  width: 20;
  font-size: 34px;
  cursor: pointer;
}
.cancelBody {
  text-align: end;
}
.overlay img {
  width: 150px;
  height: 150px;
}
.quantityBox button {
  background-color: transparent;
  border: none;
}
.buttons {
  display: flex;
}
.product-item {
  position: relative;
  width: 190px !important;
  height: 310px;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  text-align: start !important;
  transition: box-shadow 0.3s ease;
  .category {
    font-size: 13px;
    font-family: poppinsMedium;
    color: #787878;
    margin-top: 5px;
  }
  .name {
    font-family: poppinsMedium;
    margin-top: 5px;
    font-size: 14px;
    text-transform: capitalize;
  }
  .old-Price {
    font-family: poppinsMedium;
    color: #787878;
    text-dsecoration: line-through;
  }
  .new-price {
    font-family: poppinsBold;
    color: #4b5966;
  }
  .type1 {
    position: absolute;
    top: 10px;
    background-color: tomato;
    border-radius: 7px;
    color: white;
    font-family: poppinsSemiBold;
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px 20px;
    right: 0;
  }
  .type2 {
    position: absolute;
    top: 10px;
    background-color: #5caf90;
    text-transform: uppercase;
    border-radius: 7px;
    color: white;
    font-family: poppinsSemiBold;
    font-size: 12px;
    padding: 5px 20px;
    right: 0;
  }
}
.product-image {
  border-bottom: 1px solid #e4e3e3;
  padding: 10px 0;
  text-align: center;
  img {
    object-fit: cover;
    margin: auto;
    height: 140px;
    max-width: 100%;
    transition: 350ms;
  }
}
.product-item:hover img {
  transform: scale(1.2);
}

.product-info {
  padding: 10px;
}

.product-info h3 {
  font-size: 18px;
  margin: 5px 0;
}

.product-info p {
  font-size: 16px;
  color: #666;
}

.hover-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-item:hover .hover-buttons {
  opacity: 1;
}

.hover-buttons button {
  padding: 5px 10px 7px 10px;
  border: none;
  border-radius: 5px;
  border: 1px solid #e4e3e3;
  color: #fff;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;
}
.hover-buttons button img {
  transition: 350ms;
}

.hover-buttons button:hover img {
  transform: scale(0.9);
}
.add-cart-item {
  background-color: var(--default-color);
  padding: 4px;
  width: 100%;
  border: none;
  color: white;
  font-family: poppinsSemiBold;
  font-size: 15px;
  border-radius: 5px;
}
.trending-cont {
  width: 100% !important;
  overflow: hidden !important;
}
.popular-container {
  padding: 10px 40px;
}

@media screen and (max-width: 768px) {
  .hover-buttons {
    opacity: 1;
  }
}
@media screen and (max-width: 530px) {
  .product-item {
    width: 200px !important;
  }
  .trend {
    padding: 20px;
  }
}

@media screen and (max-width: 442px) {
  .product-item {
    margin-top: 20px;
    width: 190px !important;
  }
}
@media screen and (max-width: 450px) {
  .product-item {
    margin-top: 20px;
    width: 180px !important;
  }
}
@media screen and (max-width: 432px) {
  .product-item {
    margin-top: 20px;
    width: 180px !important;
  }
}
@media screen and (max-width: 412px) {
  .product-item {
    margin-top: 20px;
    height: 340px;
    width: 170px !important;
  }
}
@media screen and (max-width: 390px) {
  .product-item {
    margin-top: 20px;
    width: 100% !important;
  }
  .trend {
    padding: 5px;
  }
}
@media screen and (max-width: 390px) {
  .product-item {
    margin-top: 20px;
    width: 165px !important;
  }
}

@media screen and (max-width: 380px) {
  .product-item {
    margin-top: 20px;
    width: 100% !important;
  }
}

@media screen and (max-width: 420px) {
  .quantityBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border: 2px solid #ecebe4;
    width: 170px !important;
    padding: 5px 10px 5px 10px !important;
    font-size: 20px;
    font-size: 16px;
  }

  .cancel {
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .overlay {
    height: 100%; /* Use full viewport height */
  }
  .buttons {
    display: block;
  }

  .com {
    display: flex !important;
    align-items: center;
  }

  .overlay img {
    margin-bottom: 20px;
  }
  .successMessage {
    margin-top: 40px;
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
  .wishListBtn {
    right: 20px;
  }
}
