:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@font-face {
  font-family: poppinsBold;
  src: url(../font/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(../font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: poppinsLight;
  src: url(../font/Poppins-Light.ttf);
}
@font-face {
  font-family: poppinsMedium;
  src: url(../font/Poppins-Medium.ttf);
}
.footer {
  margin-top: 200px;
  border-top: 1px solid rgba(0, 0, 0, 0.233);
  padding: 60px;
}
.company-header {
  font-family: poppinsBold;
  font-size: 25px;
  span {
    color: var(--default-color);
  }
}
.about-company {
  font-family: poppinsMedium;
  font-size: 14px;
  margin-top: 10px;
  color: #1e1e1e97;
}
.footer-head {
  font-family: poppinsSemiBold;
  font-size: 16px;
  text-transform: capitalize;
}
.footer-head::first-letter {
  font-size: 20px;
}
.footer-link-list {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
  margin-top: 10px;
  padding: 0;
  li {
    list-style-type: none;
    .Footer-Link {
      color: #1e1e1e97;
      font-size: 14px;
      text-decoration: none;
      font-family: poppinsMedium;
      text-transform: capitalize;
    }
  }
}
.contact {
  font-family: poppinsMedium;
  font-size: 15px;
  color: #1e1e1e97;
}
.socials {
  display: flex;
  align-items: center;
  gap: 10px;
}
.socials div {
  cursor: pointer;
}
.copywrite {
  width: 100%;
  padding: 20px;
  text-align: center;
  font-family: poppinsMedium;
  font-size: 14px;
  color: #00000096;
  background-color: #0000000f;
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 50px 20px;
  }
  .footer-head {
    margin-top: 20px;
  }
}
